import React from 'react';
import PropTypes from 'prop-types';

// Image assets
import visa from '../../images/visa.png';
import mastercard from '../../images/mastercard.png';
import amex from '../../images/americanexpress.png';

export default function CardFields({user}) {
    let cardSection = null;

    const cardImages = {
        visa,
        mastercard,
        amex
    };

    if (user?.cardType) {
        if (user.cardType.includes('American Express')) {
            user.cardType = 'amex';
        }
        const cardType = user.cardType.trim().toLowerCase().replace(/\s+/igm, '');
        const cardImg = cardImages[cardType];
        const expiryDateFormatted = `${user.cardExpiry.substr(5, 2)}/${user.cardExpiry.substr(2, 2)}`;

        cardSection = (
            <div className="staticfield-card">
                <div className="staticfield-card">
                    <div className="staticfield-cardnumber"><img src={cardImg} alt={user.cardType} /> •••• •••• •••• {user.cardLast4}</div>
                    <div className="staticfield-value">Expires {expiryDateFormatted}</div>
                </div>
            </div>
        );
    } else {
        cardSection = (
            <div className="staticfield-card">
                <div className="staticfield-card">
                    <p>No credit card</p>
                </div>
            </div>
        );
    }

    return cardSection;
}

CardFields.propTypes = {
    user: PropTypes.shape({
        cardType: PropTypes.string,
        cardExpiry: PropTypes.string,
        cardLast4: PropTypes.string
    }).isRequired
};
