import {add, formatISO, differenceInDays} from 'date-fns';
import * as Sentry from '@sentry/react';
import JWTokenHandler from '../utils/JWTokenHandler';
import hydrateProduct from './productHydrator';
import parentWindowDataChannel from './ParentWindowDataChannel';
import getCheckoutRoute from '../utils/get-checkout-route';
import getSubscriptionStatus from '../utils/get-subscription-status';
import getAutoPriceId from '../utils/get-auto-price-id';
import {identifyUser} from '../utils/analytics-utils';
import getPendingSubscription from '../utils/get-pending-subscription';
import posthog from 'posthog-js';
import {BadRequestError} from '@tryghost/errors';
import safeJsonParse from '@tryghost/safe-json-parse';

// Start listening to incoming messages from Ghost Admin
parentWindowDataChannel.subscribe('getSubscription', handleSubscriptionRequest);

// Initialise billing data from local storage if available
let _billingData = null;
let _jwt = null;
let _multiSiteUser = false;

const createLoggingError = (error, fallbackMessage) => {
    const loggingError = new Error(error?.errors?.message || error?.message || fallbackMessage);
    loggingError.originalError = error?.errors || error;
    loggingError.status = error?.errors?.status || error?.status;
    return loggingError;
};

const verbose = (process?.env?.REACT_APP_VERBOSE && process?.env?.REACT_APP_VERBOSE === 'true') || false;
const cancelAtPeriodFlowEnabled = (process?.env?.REACT_APP_CANCEL_AT_PERIOD_END && process?.env?.REACT_APP_CANCEL_AT_PERIOD_END === 'true') || false;

const jwTokenHandler = new JWTokenHandler({verbose});

const BILLING_MODELS = {
    users: getBillingData.bind(),
    blogs: getBillingData.bind(),
    subscription: getBillingData.bind(),
    currentPrice: getBillingData.bind(),
    currentSite: getCurrentSite.bind(),
    currentSiteLimits: getCurrentSite.bind(),
    currentProduct: getCurrentAndAvailableProducts.bind(),
    availableProducts: getCurrentAndAvailableProducts.bind(),
    invoices: getInvoices.bind(),
    invoiceDetails: getInvoiceDetails.bind(),
    latestInvoice: getLatestInvoice.bind()
};

const makeRequest = async ({
    uri,
    data,
    headers,
    options,
    jwt
}) => {
    Sentry.setContext('request_data', {uri, data, headers, options, jwt});
    Sentry.setTags({
        source: 'makeRequest'
    });

    if (!jwt) {
        const newJwt = await jwTokenHandler.init();
        if (!newJwt || newJwt?.errors) {
            if (!newJwt) {
                const loggingError = new BadRequestError({
                    message: 'No JWT received from token handler'
                });
                Sentry.captureException(loggingError);
            } else {
                Sentry.withScope((scope) => {
                    scope.setTag('source', 'makeRequest');
                    scope.setContext('api_response', newJwt?.errors);
                    scope.setTag('api_response_status', newJwt?.errors?.status);
                    scope.setContext('full_error', newJwt?.errors);
                    Sentry.captureMessage('Error receiving JWT');
                });
            }

            return;
        }
        jwt = newJwt;
    }

    const body = JSON.stringify({
        jwt,
        data,
        options
    });

    // Set default headers
    if (!headers) {
        headers = {
            'Content-Type': 'application/json'
        };
    }

    if (!uri) {
        throw new Error('Endpoint must be provided');
    }

    const res = await window.fetch(uri, {
        method: 'POST',
        headers,
        body
    });

    if (!res) {
        const loggingError = new Error('No response received');
        Sentry.captureException(loggingError);
        throw loggingError;
    }

    Sentry.setContext('api_response', res);

    let response = null;

    if (res?.status === 202 || res?.status === 204) {
        // Handle response code from background functions (202) or
        // DELETE request with no response body (204)
        return res;
    }

    if (!res.ok) {
        // gracefully catch Netlify function timeouts
        try {
            const text = await res.text();

            if (text.match(/timeout/i)) {
                const loggingError = new Error('Netlify function timeout');
                loggingError.originalError = text;
                Sentry.captureException(loggingError, {
                    contexts: {
                        full_error: safeJsonParse(text)
                    },
                    tags: {
                        api_response_status: res?.status
                    }
                });

                const error = {
                    message: 'Your request timed out. Refresh the page and try again or contact support@ghost.org',
                    status: 408,
                    originalError: text
                };
                return {errors: error};
            }

            if (text.match(/token has expired/i)) {
                Sentry.addBreadcrumb({
                    category: 'makeRequest',
                    message: 'JWT expired',
                    level: 'debug'
                });
                jwt = await jwTokenHandler.init();
                // Make sure we update the globally stored jwt value
                _jwt = jwt;
                // Retry the request with the new JWT
                return makeRequest.call(this, {uri, data, jwt});
            }

            // Handle Stripe payment failure in grace period, all other errors need to be passed through and generalised
            if (text.match(/STRIPE_PAYMENT_FAILED/i) && _billingData?.isGrace) {
                const json = safeJsonParse(text);

                Sentry.withScope((scope) => {
                    scope.setTag('source', 'makeRequest');
                    scope.setContext('api_response', json || text);
                    scope.setContext('full_error', json || text);
                    Sentry.captureMessage('Stripe payment failed');
                });

                if (json && json.errors) {
                    return {errors: {message: `Error processing payment. ${json.errors.message}`, originalError: json.errors}};
                } else {
                    return {errors: {message: 'Error processing payment. Try again or contact support@ghost.org', originalError: text}};
                }
            }

            return {errors: {message: 'Something went wrong. Refresh the page and try again or contact support@ghost.org', originalError: text}};
        } catch (error) {
            Sentry.captureException(error);

            const err = {
                message: error?.errors?.message || 'Error receiving billing data',
                status: error?.errors?.status,
                originalError: error
            };

            return {errors: err};
        }
    } else {
        response = await res.json();

        if (response?.errors && response?.errors?.message === 'Token has expired') {
            Sentry.addBreadcrumb({
                category: 'makeRequest',
                message: 'JWT expired',
                level: 'debug'
            });
            jwt = await jwTokenHandler.init();
            // Make sure we update the globally stored jwt value
            _jwt = jwt;
            // Retry the request with the new JWT
            return makeRequest.call(this, {uri, data, jwt});
        }
    }

    if (verbose) {
        console.log(`POST ${uri} response:\n`, response);
    }

    return response;
};

function handleSubscriptionRequest(cb) {
    getPartialCachedBillingData('subscription').then(subscription => cb({subscription: subscription}));
}

async function setForceUpgrade(forceUpgradeInfo) {
    // Set the force upgrade tag for Sentry globally. This will be updated
    // every time we check for force upgrade info again.
    Sentry.setTag('force_upgrade', forceUpgradeInfo?.forceUpgrade || false);

    _billingData = {
        ..._billingData,
        forceUpgradeNoOwner: forceUpgradeInfo.isOwner === false && forceUpgradeInfo.forceUpgrade === true,
        forceUpgradeOwner: forceUpgradeInfo.isOwner === true && forceUpgradeInfo.forceUpgrade === true,
        isForceUpgrade: forceUpgradeInfo.forceUpgrade === true,
        siteOwner: forceUpgradeInfo?.ownerUser
    };

    if (verbose) {
        console.log('[API] billingData after setting forceUpgradeInfo', forceUpgradeInfo);
    }

    return _billingData;
}

export async function getForceUpgrade() {
    const forceUpgradeInfo = await parentWindowDataChannel.getForceUpgradeInfo();
    if (verbose) {
        console.log('[API] forceUpgradeInfo', forceUpgradeInfo);
    }
    return await setForceUpgrade(forceUpgradeInfo);
}

export async function getPartialBillingData(model, data) {
    if (!model) {
        throw new Error('Function must be called with a model identifier');
    }

    if (!BILLING_MODELS[model]) {
        throw new Error(`Not a valid model identifier '${model}'`);
    }

    const partialBillingData = await BILLING_MODELS[model].apply(data);

    _billingData = {
        ..._billingData,
        ...partialBillingData
    };

    return _billingData[model];
}

export async function getPartialCachedBillingData(model) {
    if (!model) {
        throw new Error('Function must be called with a model identifier');
    }

    if (!_billingData) {
        await getInitialBillingData();
    }

    if (!BILLING_MODELS[model]) {
        throw new Error(`Not a valid model identifier '${model}'`);
    }

    if (!_billingData[model]) {
        await getPartialBillingData(model);
    }

    return _billingData[model];
}

export async function getCachedBillingData() {
    if (!_billingData) {
        return await getInitialBillingData();
    }

    if (verbose) {
        console.log('[API] return cached Billing data:', _billingData);
    }
    return _billingData;
}

// This should only be run when absolutely no subscription info on hand yet
// This is the minimal required information to render the Dashboard
export async function getInitialBillingData() {
    Sentry.setTag('source', 'getInitialBillingData');

    try {
        const jwt = await jwTokenHandler.init();

        if (!jwt || jwt?.errors) {
            // TODO: handle unauthorized errors here as well. When user is not the owner,
            // Ghost Admin returns `null` as the token response, so we should actually be prepared
            // for this case and not log this as an error.
            if (jwt?.errors?.message && jwt?.errors?.message.match(/legacy/gmi)) {
                if (verbose) {
                    console.log('[API] getInitialBillingData - Legacy user: ', jwt?.errors?.message);
                }
                _multiSiteUser = true;
                Sentry.setTag('legacy', true);
            } else if (jwt?.errors) {
                Sentry.withScope((scope) => {
                    scope.setTag('source', 'getInitialBillingData');
                    scope.setContext('full_error', jwt?.errors);
                    Sentry.captureMessage('Error receiving JWT');
                });
            }

            _multiSiteUser = false;

            _billingData = _billingData || {};
            // User is not the owner and doesn't have permission to read the data.
            // Stop with any further action
            _billingData.errors = {
                message: jwt?.errors?.message || 'Error receiving billing data, no permission',
                status: jwt?.errors?.status || null
            };

            jwTokenHandler.clearSessionInterval();

            return _billingData;
        }

        _jwt = jwt;

        const forceUpgradeInfo = await parentWindowDataChannel.getForceUpgradeInfo();

        if (forceUpgradeInfo) {
            await setForceUpgrade(forceUpgradeInfo);

            if (_billingData?.forceUpgradeNoOwner) {
                // don't even try fetching additional information for non-owner users
                return _billingData;
            }
        }

        // Step 1 returns users, blogs, and subscription data
        const billingData = await getBillingData({initialRequest: true});

        if (billingData?.user?.email_address) {
            identifyUser(posthog, billingData.user.email_address);

            // Globally identify the user in Sentry
            Sentry.setUser({
                id: billingData.user.id,
                email: billingData.user.email_address,
                username: billingData.user.stripe_token
            });
        }

        if (billingData?.errors) {
            if (billingData.errors?.status === 403) {
                if (verbose) {
                    console.log('[API] getInitialBillingData - Legacy user: ', billingData.errors?.message);
                }
                _multiSiteUser = true;

                Sentry.setTag('legacy', true);

                // Don't proceed further with requests, the customer is on a legacy plan and can't use the BMA
                jwTokenHandler.clearSessionInterval();
            }

            return billingData;
        }

        _multiSiteUser = false;

        const customerId = billingData?.subscription?.customer_id || billingData?.user?.stripe_token;

        // Step 2 returns additional billing information, that can be updated independently
        const [products, invoiceDetails, invoices, currentSite, discountDetails] = await Promise.all([
            getCurrentAndAvailableProducts({stripePrice: _billingData?.subscription?.meta?.baseProduct?.stripe_price_id}),
            getInvoiceDetails({customerId}),
            getInvoices({userId: _billingData?.user?.id}),
            getCurrentSite({data: _billingData.blogs}),
            getDiscountDetails({customerId})
        ]);

        if (products?.errors || invoiceDetails?.errors || invoices?.errors || currentSite?.errors || discountDetails?.errors) {
            const loggingError = new Error('Failed to fetch additional billing information');
            loggingError.originalError = products?.errors || invoiceDetails?.errors || invoices?.errors || currentSite?.errors || discountDetails?.errors;
            Sentry.captureException(loggingError, {
                contexts: {
                    full_error: products?.errors || invoiceDetails?.errors || invoices?.errors || currentSite?.errors || discountDetails?.errors
                }
            });
        }

        _billingData = {
            ..._billingData,
            ...billingData,
            // Additional information that needs to be requested from Daisy
            ...products,
            ...invoices,
            ...currentSite,
            // Stripe endpoint
            ...invoiceDetails,
            ...discountDetails
        };

        jwTokenHandler.maintainSession();

        // Check if we have a pending subscription in our local storage
        // and ensure it's set for incomplete and auto billing grace customers
        _billingData.pendingSubscription = getPendingSubscription(_billingData);
        _billingData.checkoutRoute = getCheckoutRoute(_billingData);
        _billingData.autoPriceId = getAutoPriceId({baseProduct: _billingData?.subscription?.meta?.baseProduct, availableProducts: _billingData?.availableProducts});
    } catch (error) {
        Sentry.captureException(error);

        if (verbose) {
            console.log('[API] getInitialBillingData error', error);
        }
        _billingData = _billingData || {};

        jwTokenHandler.clearSessionInterval();

        _billingData.errors = {
            message: error?.errors?.message || 'Error receiving billing data',
            status: error?.errors?.status,
            originalError: error
        };
    }

    if (!_billingData.availableProducts && !_billingData?.errors) {
        Sentry.captureException(new Error('No available products found'));

        return _billingData.errors = {message: 'Error receiving billing data'};
    }

    // Pass information to Ghost Admin
    await parentWindowDataChannel.sendSubscriptionData(_billingData);

    if (verbose) {
        console.log('[API] _billingData', _billingData);
    }

    return _billingData;
}

// Get fresh data for users, blogs, and subscription
export async function getBillingData(options = {}) {
    let billingDataResponse = {};

    Sentry.setTag('source', 'getBillingData');

    // In a forceUpgrade state, we won't receive any subscriptions
    // Set the flag to let the backend know to skip searching for a
    // current subscription
    if (_billingData.forceUpgradeOwner) {
        options.forceUpgrade = true;
    }

    if (!options?.initialRequest && _multiSiteUser) {
        if (verbose) {
            console.log('[API] getBillingData - not initial request: ', options);
        }
        return _billingData;
    }

    try {
        // Step 1 returns users, blogs, and subscription data
        billingDataResponse = await makeRequest({
            uri: '/api/get-subscription',
            options,
            jwt: _jwt
        });

        if (billingDataResponse?.errors) {
            if (billingDataResponse?.errors?.status === 403) {
                if (verbose) {
                    console.log('[API] getBillingData - Legacy user: ', billingDataResponse.errors?.message);
                }

                _multiSiteUser = true;

                Sentry.setTag('legacy', true);
            }
            // Don't proceed further with requests, the customer is on a legacy plan and can't use the BMA
            return billingDataResponse;
        }

        _multiSiteUser = false;

        const customerId = billingDataResponse?.subscription?.customer_id || billingDataResponse?.user?.stripe_token;

        // We need to check the latest invoice to see if there is any open. If there is, it means there's a pending update
        // that hasn't been finished.
        const latestInvoice = await getLatestInvoice({customerId});

        // With the information about the latest invoice we can determine the correct subscription status
        const subscriptionStatus = getSubscriptionStatus(billingDataResponse?.subscription, latestInvoice, verbose);

        if (latestInvoice?.payment_intent?.client_secret && !latestInvoice?.payment_intent?.status === 'canceled') {
            billingDataResponse.subscription.meta.paymentIntentSecret = latestInvoice.payment_intent.client_secret;
        }

        if (billingDataResponse?.subscription?.status === 'incomplete' && options?.sca === 'succeeded') {
            Sentry.addBreadcrumb({
                category: 'getBillingData_incomplete_sca_succeeded',
                message: 'Was incomplete subscription, SCA succeeded, manually setting to active',
                level: 'info'
            });

            // Manually overwrite the subscription status, as we might have a timing
            // issue with the webhook being sent and Daisy processing it. The SCA
            // was a success and the payment is completed, therefore the subscription
            // should manually be set to `active`, at least until the next refresh
            billingDataResponse.subscription.status = 'active';
        } else if (billingDataResponse?.isPending && options?.sca === 'succeeded') {
            Sentry.addBreadcrumb({
                category: 'getBillingData_pending_sca_succeeded',
                message: 'Was pending subscription, SCA succeeded, manually setting to false',
                level: 'info'
            });

            billingDataResponse.isPending = false;
        }

        // If we don't have a coupon on the user, ensure we don't provide
        // coupon details in the billing data
        if (!billingDataResponse?.user?.coupon) {
            _billingData.discount = {};
        // When we have a coupon saved on the user, but it doesn't match the one
        // we already fetched the details for, update the details for the updated
        // coupon and merge it into the billingDataResponse
        } else if (billingDataResponse?.user?.coupon !== _billingData?.discount?.coupon?.id) {
            const discountDetails = await getDiscountDetails({customerId: billingDataResponse?.user?.stripe_token_sg});
            billingDataResponse = {
                ...billingDataResponse,
                ...discountDetails
            };
        }

        _billingData = {
            ..._billingData,
            ...billingDataResponse,
            ...subscriptionStatus
        };

        if (_billingData?.subscription) {
            /**
             * This is needed for Ghost Admin to render the Upgrade CTA
             * @todo: rename the property from `isActiveTrial` to something more descriptive, as it also includes
             * incomplete subscriptions and subscriptions in auto-billing grace period
             */
            _billingData.subscription.isActiveTrial = _billingData?.isTrial || _billingData?.isIncomplete || _billingData?.isAutoBillingGrace;

            // TODO: remove flag, once fully in production
            // Determine if the subscription will be cancelled at the end of the current billing period
            if (cancelAtPeriodFlowEnabled && _billingData?.subscription?.cancelled_at && !_billingData?.isTrial) {
                const cancelledAt = new Date(_billingData?.subscription?.cancelled_at);
                const daysUntilCancellation = differenceInDays(cancelledAt, new Date());

                _billingData.willCancelAtPeriodEnd = daysUntilCancellation >= 1;
            }
        }

        _billingData.autoPriceId = getAutoPriceId({baseProduct: _billingData?.subscription?.meta?.baseProduct, availableProducts: _billingData?.availableProducts});

        if (_billingData?.availableProducts && _billingData?.latestInvoice && _billingData?.currentSiteLimits) {
            _billingData.pendingSubscription = getPendingSubscription(_billingData);
            _billingData.checkoutRoute = getCheckoutRoute(_billingData);
        }

        if (verbose) {
            console.log('[API] updated _billingData in getBillingData: ', _billingData);
        }

        if (options.updateAdmin) {
            await parentWindowDataChannel.sendSubscriptionData(_billingData);
        }

        return _billingData;
    } catch (error) {
        Sentry.captureException(error);
        billingDataResponse = {
            errors: {
                message: error?.errors?.message || 'Error receiving subscription information',
                status: error?.errors?.status,
                originalError: error
            }
        };
    }

    _billingData = {
        ..._billingData,
        ...billingDataResponse
    };

    if (options.updateAdmin) {
        await parentWindowDataChannel.sendSubscriptionData(_billingData);
    }

    return _billingData;
}

// Takes an array of blogs and returns the currently active site depending on
// the hostname as taken from the JWT and the staff limit.
// If the current site id is already available, we use that instead and fetch
// the data fresh from the backend
export async function getCurrentSite(data) {
    Sentry.setTag('source', 'getCurrentSite');
    Sentry.setContext('request_data', data);

    const options = {};
    let currentSite = {};
    let blogs = data?.blogs || _billingData?.blogs;
    let siteId = _billingData?.currentSite?.id || null;

    if (_multiSiteUser) {
        Sentry.setTag('legacy', true);
        return _billingData;
    }

    if (!blogs) {
        const billingDataResponse = await getBillingData({initialRequest: true});
        blogs = billingDataResponse?.blogs;
        Sentry.setExtra('siteId', billingDataResponse?.blogs?.[0]?.id);
    }

    if (_billingData?.forceUpgradeOwner) {
        options.forceUpgrade = true;
    }

    try {
        currentSite = await makeRequest({
            uri: '/api/get-current-site',
            data: {
                blogs,
                siteId
            },
            options,
            jwt: _jwt
        });

        if (currentSite?.errors?.message?.match(/special legacy plan/)) {
            jwTokenHandler.clearSessionInterval();
            // Don't proceed further with requests, the customer is on a legacy plan and can't use the BMA
            return currentSite.errors;
        }

        // Check if the customer has exceeded any limits
        if (currentSite?.currentSiteLimits) {
            const limitNames = Object.keys(currentSite.currentSiteLimits);

            const exceededLimits = limitNames.filter((limit) => {
                // Case: we have a members_override set, so we need to compare the total
                // against this instead of the value of the price. This is how the limit
                // service in Ghost decides to block post publishing and we should follow
                // the same logic here.
                // If we pass objects in the `exceeded` array to Ghost Admin, Ghost will
                // render the upgrade banner, which we want to avoid in this case.
                if (limit === 'members' && currentSite.currentSiteLimits[limit]?.members_override) {
                    return currentSite.currentSiteLimits[limit]?.total > currentSite.currentSiteLimits[limit]?.members_override;
                } else {
                    return currentSite.currentSiteLimits[limit].exceeded;
                }
            });

            // Set exceeded limits to Sentry context
            Sentry.setContext('exceeded_limits', exceededLimits?.reduce((acc, limit) => {
                acc[limit] = true;
                return acc;
            }, {}));

            _billingData = {
                ..._billingData,
                ...currentSite,
                exceededLimits
            };

            const subscriptionStatus = getSubscriptionStatus(_billingData.subscription, _billingData.latestInvoice);

            const pendingSubscription = getPendingSubscription(_billingData);
            const checkoutRoute = getCheckoutRoute(_billingData);

            if (verbose) {
                console.log('[API] checkoutRoute', checkoutRoute);
            }

            _billingData = {
                ..._billingData,
                ...subscriptionStatus,
                pendingSubscription,
                checkoutRoute
            };
        }
    } catch (error) {
        Sentry.captureException(error);

        currentSite.errors = {
            message: error?.errors?.message || 'Error receiving current site information',
            status: error?.errors?.status,
            originalError: error
        };
    }

    return currentSite;
}

export async function getCurrentAndAvailableProducts(data) {
    Sentry.setTag('source', 'getCurrentAndAvailableProducts');
    Sentry.setContext('request_data', data);

    let res = {};
    let stripePrice = data?.stripePrice || _billingData?.subscription?.meta?.baseProduct?.stripe_price_id;
    let availableProducts = data?.availableProducts || _billingData?.availableProducts || null;
    /** @type {string|null} */
    const productGroup = _billingData?.user?.show_product_group || null;
    let currentPrice = null;

    if (_multiSiteUser) {
        Sentry.setTag('legacy', true);
        return _billingData;
    }

    if (!stripePrice) {
        const billingData = await getBillingData();
        stripePrice = billingData?.subscription?.meta?.baseProduct?.stripe_price_id;
    }

    try {
        res = await makeRequest({
            uri: '/api/get-current-plan',
            data: {
                stripePrice,
                availableProducts,
                productGroup
            },
            jwt: _jwt
        });

        if (res?.availableProducts && res.availableProducts.length) {
            if (verbose) {
                console.log('[API] Hydrating available products');
            }
            availableProducts = res?.availableProducts.map(hydrateProduct);
        } else {
            Sentry.captureException(new Error('No available products found'));
        }

        if (res?.currentPrice && !res.currentPrice.legacy) {
            currentPrice = res.currentPrice ? hydrateProduct(res.currentPrice) : null;

            if (verbose) {
                console.log('[API] Current Price: ', currentPrice);
            }
        } else if (res?.currentPrice && res?.currentPrice?.legacy) {
            currentPrice = res.currentPrice ? hydrateProduct(res.currentPrice) : null;

            if (verbose) {
                console.log('[API] Current Legacy Price: ', currentPrice);
            }
        } else if (!res.currentPrice && !_billingData?.isForceUpgrade) {
            Sentry.captureException(new Error('No current price found'));
        }

        const products = {
            availableProducts,
            currentPrice
        };

        _billingData = {
            ..._billingData,
            ...products
        };

        return products;
    } catch (error) {
        Sentry.captureException(error);

        res.errors = {
            message: error?.errors?.message || 'Error receiving products and prices',
            status: error?.errors?.status,
            originalError: error
        };
    }
}

export async function getInvoiceDetails(data) {
    Sentry.setTag('source', 'getInvoiceDetails');
    Sentry.setContext('request_data', data);

    let res = {};
    let customerId = data?.customerId || _billingData?.subscription?.customer_id || _billingData?.user?.stripe_token;

    if (_multiSiteUser) {
        return _billingData;
    }

    if (!customerId) {
        const billingData = await getBillingData();
        customerId = billingData?.subscription?.customer_id || billingData?.user?.stripe_token;
    }

    try {
        res = await makeRequest({
            uri: '/api/get-invoice-details',
            data: {
                customerId
            },
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);

        res.errors = {message: 'Error getting invoice details', originalError: error};
    }

    return res;
}

export async function getInvoices(data) {
    Sentry.setTag('source', 'getInvoices');
    Sentry.setContext('request_data', data);

    let res = {};
    let userId = data?.userId || _billingData?.user.id;

    if (_multiSiteUser) {
        return _billingData;
    }

    if (!userId) {
        const billingData = await getBillingData();
        userId = billingData?.user?.id;
    }

    try {
        res = await makeRequest({
            uri: '/api/get-invoices',
            data: {
                userId
            },
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        if (verbose) {
            console.log('[API] → getInvoices → error', error);
        }
        res.errors = {message: 'Error fetching invoices', originalError: error};
    }

    return res;
}

export async function setupIntent(data) {
    Sentry.setTag('source', 'setupIntent');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/setup-card',
            data,
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error updating credit card', originalError: error};
    }

    return res;
}

/**
 * @description Fetches the Stripe portal URL for the given customer ID and return URL
 * @param {{customerId: string, returnUrl: string}} data
 * @returns {Promise<{url: string, errors: object}>}
 */
export async function getStripePortalUrl(data) {
    Sentry.setTag('source', 'getStripePortalUrl');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/get-stripe-portal-url',
            data,
            jwt: _jwt
        });

        const {url: stripePortalUrl, errors} = res;

        return {
            url: stripePortalUrl,
            errors
        };
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error fetching Stripe portal URL', originalError: error};
    }

    return res;
}

/**
 *
 * @param {object} data
 * @param {string} data.customerId
 * @returns {Promise<object>}
 */
export async function fetchPaymentMethod(data) {
    Sentry.setTag('source', 'fetchPaymentMethod');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/payment-method',
            data,
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error fetching payment method', originalError: error};
    }

    return res;
}

export async function updateCreditCard(data) {
    Sentry.setTag('source', 'updateCreditCard');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/update-card',
            data,
            jwt: _jwt
        });

        const {user, errors} = res;

        _billingData = {
            ..._billingData,
            user,
            errors
        };

        return _billingData;
    } catch (error) {
        Sentry.captureException(error);
        // Filter out specific Stripe error message for when the customer has insufficient funds
        // but only show it to the user when they are in the grace period
        const errorMessage = _billingData?.isGrace && error?.message ? error.message : 'Error updating credit card';
        res.errors = {message: errorMessage, originalError: error};
    }

    return res;
}

export async function addInvoiceDetails(data) {
    Sentry.setTag('source', 'addInvoiceDetails');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/add-invoice-details',
            data,
            jwt: _jwt
        });

        const {invoiceDetails, errors} = res;

        _billingData = {
            ..._billingData,
            invoiceDetails,
            errors
        };

        return _billingData;
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error adding invoice details', originalError: error};
    }

    return res;
}

export async function updateBillingContact(data) {
    Sentry.setTag('source', 'updateBillingContact');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/update-billing-contact',
            data,
            jwt: _jwt
        });

        const {user, errors} = res;

        _billingData = {
            ..._billingData,
            user,
            errors
        };

        return _billingData;
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error updating billing contact', originalError: error};
    }

    return res;
}

export async function getLatestInvoice(data) {
    Sentry.setTag('source', 'getLatestInvoice');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/get-latest-invoice',
            data,
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error fetching latest invoice', originalError: error};
    }

    _billingData = {
        ..._billingData,
        latestInvoice: res,
        errors: res?.errors
    };

    return res;
}

export async function getInvoicePreview(data) {
    Sentry.setTag('source', 'getInvoicePreview');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/get-invoice-preview',
            data,
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error fetching invoice preview', originalError: error};
    }

    return res;
}

/**
 * @param {{customerId: string}} data
 * @returns {Promise<object>}
 */
export async function getDiscountDetails(data) {
    Sentry.setTag('source', 'getDiscountDetails');
    Sentry.setContext('request_data', data);

    let res = {};

    if (!data?.customerId) {
        return res;
    }

    try {
        res = await makeRequest({
            uri: '/api/get-discount-details',
            data,
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error getting coupon details', originalError: error};
    }

    return res;
}

/**
 * @param {{customerId: string}} data
 * @returns {Promise<{balance: number|errors: object}>}
 */
export async function getCustomerBalance(data) {
    Sentry.setTag('source', 'getCustomerBalance');
    Sentry.setContext('request_data', data);

    let res = {
        balance: 0
    };

    try {
        res = await makeRequest({
            uri: '/api/get-customer-balance',
            data,
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error fetching customer balance', originalError: error};
    }

    return res;
}

/**
 * @param {{subId: string, includeCancelled?: boolean}} data
 * @returns {Promise<object>}
 */
export async function getSubscription(data) {
    Sentry.setTag('source', 'getSubscription');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/get-subscription',
            data,
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error fetching subscription', originalError: error};
    }

    return res;
}

/**
 *
 * @param {object} data
 * @param {string} data.paymentMethodId
 * @param {string} data.customerId
 * @param {string} data.planId
 * @param {string?} data.subscriptionId
 * @param {boolean?} data.isTrial
 * @param {object[string]?} data.addonIds
 * @param {number?} data.prorationDate
 * @param {string} data.userId
 * @returns {Promise<object>}
 */
export async function changeOrActivateSubscriptionPlan(data) {
    Sentry.setTag('source', 'changeOrActivateSubscriptionPlan');
    Sentry.setContext('request_data', data);

    let res = {};
    // Hit the activateSubscription endpoint when the site is in a forceUpgrade state (=no subscription to change from)
    const method = _billingData?.forceUpgradeOwner ? '/api/activate-subscription' : '/api/change-subscription';

    Sentry.setExtra('method', method);

    try {
        res = await makeRequest({
            uri: method,
            data,
            jwt: _jwt
        });

        // Meta can contain messages, or e. g. the `client_secret` for the PaymentIntent
        let {subscription, errors, user, meta} = res;

        if (errors) {
            const loggingError = createLoggingError(errors, 'Error changing or activating subscription');
            Sentry.captureException(loggingError);
            return {
                errors
            };
        }

        user = user || _billingData.user;

        if (meta) {
            subscription.meta = Object.assign({}, subscription.meta, meta);
        }

        if (_billingData.subscription.current_period_end !== subscription.current_period_end) {
            // Always keep the current period end date, so we don't have to fetch it again
            subscription.current_period_end = _billingData.subscription.current_period_end;
        }

        _billingData = {
            ..._billingData,
            subscription,
            user,
            errors
        };

        const latestInvoice = await getLatestInvoice({customerId: subscription?.customer_id || user?.stripe_jwt});
        const subscriptionStatus = getSubscriptionStatus(_billingData.subscription, latestInvoice, verbose);

        if (_billingData?.subscription && subscriptionStatus) {
            // This is needed for Ghost Admin to render the Upgrade CTA
            _billingData.subscription.isActiveTrial = subscriptionStatus?.isTrial ?? _billingData?.isIncomplete;
        }

        _billingData = {
            ..._billingData,
            ...subscriptionStatus
        };

        // If the plan change was successful, update the current Price
        if (!_billingData?.isPending) {
            _billingData.currentPrice = updateCurrentPrice({stripePriceId: subscription?.meta?.baseProduct?.stripe_price_id, availableProducts: _billingData.availableProducts});
        } else {
            _billingData.pendingSubscription = getPendingSubscription(_billingData);
            _billingData.checkoutRoute = getCheckoutRoute(_billingData);
        }
        _billingData.autoPriceId = getAutoPriceId({baseProduct: _billingData?.subscription?.meta?.baseProduct, availableProducts: _billingData?.availableProducts});

        if (verbose) {
            console.log('[API] updated _billingData in changeOrActivateSubscriptionPlan: ', _billingData);
        }

        // Ensure Ghost Admin knows about the updated state
        await parentWindowDataChannel.sendSubscriptionData(_billingData);

        return _billingData;
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {
            message: error?.message || 'Error changing your subscription',
            originalError: error
        };
    }

    return res;
}

export async function extendTrial(data) {
    Sentry.setTag('source', 'extendTrial');
    Sentry.setContext('request_data', data);

    const trialEndOld = _billingData?.subscription?.trial_end;
    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/extend-trial',
            data,
            jwt: _jwt
        });

        let {subscription, errors} = res;

        if (errors) {
            const loggingError = createLoggingError(errors, 'Error extending trial');
            Sentry.captureException(loggingError);
            return {
                errors
            };
        }

        _billingData.subscription = Object.assign({}, subscription, _billingData?.subscription);

        if (_billingData.subscription.trial_end === trialEndOld) {
            // Daisy didn't return the new trial end date, fix it ourselves for now, it will be corrected as soon as fresh data is fetched from the server
            const newTrialEnd = formatISO(add(new Date(_billingData?.subscription?.trial_start), {days: 21}));

            _billingData.subscription.trial_end = newTrialEnd;
        }

        return _billingData.subscription;
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error extending trial', originalError: error};
    }

    return res;
}

export async function cancelAccount(data) {
    Sentry.setTag('source', 'cancelAccount');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/cancel-background',
            data,
            jwt: _jwt
        });

        return res;
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error cancelling subscription', originalError: error};
    }

    return res;
}

/**
 * Takes the subscription ID and sets the subscription to cancel at the end of the current billing period
 * @param {object} data
 * @param {string} data.subscriptionId
 * @param {boolean?} data.reset
 * @returns {Promise<object>}
 */
export async function cancelAtPeriodEnd(data) {
    Sentry.setTag('source', 'cancelAtPeriodEnd');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/cancel',
            data,
            jwt: _jwt
        });

        if (res?.data?.cancelled_at) {
            _billingData.subscription.cancelled_at = res.data.cancelled_at;
            _billingData.willCancelAtPeriodEnd = true;
        } else if (!res.errors && !res?.data?.cancelled_at) {
            _billingData.willCancelAtPeriodEnd = false;
            _billingData.subscription.cancelled_at = null;
        }

        return res;
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error cancelling subscription at period end', originalError: error};
    }

    return res;
}

export async function updateCustomDomain(data) {
    Sentry.setTag('source', 'updateCustomDomain');
    Sentry.setContext('request_data', data);

    let res = {};
    // When no external domain is passed (or null) we deal with removing the custom domain.
    // This step can be done as a normal Netlify function, because the request won't time out.
    let uri = data.domain ? '/api/update-domain-background' : '/api/update-domain';

    try {
        res = await makeRequest({
            uri,
            data,
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error updating custom domain', originalError: error};
    }

    return res;
}

export async function checkCustomDomain(data) {
    Sentry.setTag('source', 'checkCustomDomain');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/check-domain',
            data,
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'private'
            },
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error checking custom domain', originalError: error};
    }

    return res;
}

export async function checkDomainDns(data) {
    Sentry.setTag('source', 'checkDomainDns');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/check-domain-dns',
            data,
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'private'
            },
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error checking domain DNS', originalError: error};
    }

    return res;
}

export async function setupSendingDomain(data) {
    Sentry.setTag('source', 'setupSendingDomain');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/setup-sending-domain',
            data,
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error setting up custom sending domain', originalError: error};
    }

    return res;
}

export async function removeSendingDomain(data) {
    Sentry.setTag('source', 'removeSendingDomain');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/remove-sending-domain',
            data,
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error removing custom sending domain', originalError: error};
    }

    return res;
}

export async function verifySendingDomain(data) {
    Sentry.setTag('source', 'verifySendingDomain');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/verify-sending-domain',
            data,
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error verifying custom sending domain', originalError: error};
    }

    return res;
}

export async function checkSendingDomain(data) {
    Sentry.setTag('source', 'checkSendingDomain');
    Sentry.setContext('request_data', data);

    let res = {};

    try {
        res = await makeRequest({
            uri: '/api/check-sending-domain',
            data,
            jwt: _jwt
        });
    } catch (error) {
        Sentry.captureException(error);
        res.errors = {message: 'Error checking custom sending domain', originalError: error};
    }

    return res;
}

export async function getJWT() {
    return await parentWindowDataChannel.getToken();
}

const updateCurrentPrice = ({availableProducts, stripePriceId}) => {
    const findCurrentPrice = (allProducts, stripePrice) => {
        const currentPrice = [];

        allProducts.forEach((product) => {
            if (product.prices.length) {
                product.prices.forEach((price) => {
                    if (price.stripe_price_id.toString() === stripePrice.toString()) {
                        price.base_product = product.name;
                        return currentPrice.push(price);
                    }
                });
            }
        });

        return currentPrice;
    };

    const [currentPrice] = stripePriceId ? findCurrentPrice(availableProducts, stripePriceId) : [];

    return currentPrice;
};
